module.exports = {
  intro: {
    title1: "推出积分支付",
    title2: "通过阿雅娜礼赏",
    subtitle: "将您的阿雅娜礼赏积分转化为阿雅娜酒店难忘的体验",
    cta: "查看价格",
  },
  title: "使用积分支付阿雅娜专属度假体验",
  pros: {
    first: {
      title: "体验独特的付款灵活性",
      description:
        "使用积分轻松支付您在阿雅娜酒店的住宿费用。 如果您的积分不足，您可以选择用现金补充以获得更好的体验。",
    },
    second: {
      title: "最大化您的储蓄",
      description:
        "将您累积的积分兑换为会员专享折扣，以无与伦比的节省提升您的体验。",
    },
  },
  preferred_dest: {
    title: "选择您首选的阿雅娜目的地",
    subtitle:
      "阿雅娜酒店遍布雅加达、巴厘岛和科莫多，拥有巴厘岛阿雅娜别墅、巴厘岛阿雅娜塞加拉、巴厘岛阿雅娜度假酒店、巴厘岛阿雅娜RIMBA、阿雅娜科莫多维艾西洙海滩、阿雅娜Lako Di'a、雅加达阿雅娜中广场和Delonix等知名品牌 加拉旺酒店。",
    bali: "峇里",
    jakarta: "大雅加达",
    komodo: "科莫多龙",
    RJB: "巴厘岛阿雅娜林畔酒店",
    ARSB: "巴厘岛阿雅娜度假酒店",
    VILLA: "巴厘岛阿雅娜度假别墅",
    AKWB: "阿雅娜科莫多维艾齐洙沙滩度假村",
    LKD: "阿雅娜Lako Di'a",
    DXKR: "加拉璜凤凰木酒店",
    JKTHB: "雅加达阿雅娜中城广场酒店",
    SEGARA: "巴厘岛阿雅娜赛格拉酒店",
  },
  how_it_works: {
    title: "在AYANA酒店，所有酒店都可以使用积分付款。",
    subtitle: "以下是使用方法：",
    first: "点击下面的“查找酒店”按钮",
    second: "选择您喜欢的日期和地点",
    third: "选择标有“可使用阿雅娜会员奖励积分”的房价。",
    fourth: "登录您的 AYANA 奖励帐户",
    fifth: "通过“使用积分支付”继续付款",
    sixth: "祝您住宿愉快！",
  },
  find_hotels: {
    title: "立即将您的积分兑换成难忘的 AYANA 时刻",
    cta: "寻找酒店",
  },
  faq: {
    title: "常见问题与解答",
    first: {
      question: "如何使用阿雅娜奖励积分创建预订？",
      answer:
        "当您选择此选项时，您的积分将用于支付预订所需的积分数量。 您可以决定要兑换多少积分来支付预订费用，并使用其他可用的付款方式支付剩余的预订金额。",
    },
    second: {
      question: "如果我使用积分付款，我可以取消预订吗？",
      answer:
        "您的订单取消政策将遵循您所选择的价格对应的取消政策。如果选择的价格可以退款，使用积分支付的金额将作为积分退还，使用其他支付方式支付的金额将根据您的支付方式退还。",
    },
    third: {
      question: "如果我使用阿雅娜会员礼遇积分支付预订，我可以赚取积分吗？",
      answer:
        "是的，您仍然可以通过我们网站创建并使用阿雅娜会员礼遇积分支付的预订，按照符合资格的费用赚取积分。 符合条件的费用因酒店而异。 请参阅我们 的条款和条件 以获取完整信息。 请注意，阿雅娜会员礼遇保留未来更改政策的权利，恕不另行通知。",
    },
  },
  tnc: "条款和条件",
};
